import request from "@/api/server/index"

// 设备列表
/*
    param num - 当前页码
    param count - 每页显示数据条数
    param equipCode - 列表搜索的设备code
    param code - 所属产品code
    param statu - 当前选中设备状态
    param mode - 当前选中创建方式
    param time1 - 最近添加时间起始日期
    param time2 - 最近添加时间结束日期
    param time3 - 最近在线时间起始日期
    param time4 - 最近在线时间结束日期
    param sign - 设备是否绑定父设备（1：查询没有绑定父设备的数据）
*/
export function getEquipList(num, count, equipCode, code, statu, mode, time1, time2, time3, time4, sign) {
    return request({
        url: '/manager/device/page',
        method: 'post',
        data: {
            page: num,
            pageSize: count,
            deviceCode: equipCode,
            productCode: code,
            status: statu,
            source: mode,
            createTimeStart: time1,
            createTimeEnd: time2,
            latestOnlineTimeStart: time3,
            latestOnlineTimeEnd: time4,
            noParent: sign
        }
    })
}

// 设备删除
/*
    param id - 当前删除的设备id
*/
export function deleteEquip(id) {
    return request({
        url: `/manager/device/remove/${id}`,
        method: 'post'
    })
}

// 批量删除设备
/*
    param ids - 当前删除的设备id集合，数组类型
*/
export function deleteEquipList(ids) {
    return request({
        url: "/manager/device/batchRemoveById",
        method: 'post',
        data: ids
    })
}

// 新增/修改设备
/*
    param equipCode - 设备code
    param codeInfo - 所属产品code
    param siteX - 经度
    param siteY - 纬度
    param site - 详细地址
    param desc - 设备描述
    param equipId - 设备id
*/
export function changeEquip(equipCode, codeInfo, siteX, siteY, site, desc, equipId) {
    return request({
        url: '/manager/device/saveOrUpdate',
        method: 'post',
        data: {
            code: equipCode,
            productCode: codeInfo,
            longitude: siteX,
            latitude: siteY,
            address: site,
            remark: desc,
            id: equipId
        }
    })
}

// 根据设备id查询
/*
    param id - 当前查询的设备id
*/
export function searchEquip(id) {
    return request({
        url: `/manager/device/get/${id}`,
        method: 'get'
    })
}

// 查询设备属性列表数据
/*
    param code1 - 当前查询的产品Code
    param code2 - 当前查询的设备Code
*/
export function EquipPropertyList(code1, code2) {
    return request({
        url: `/manager/device/getProperties/${code1}/${code2}`,
        method: 'get'
    })
}

// 查询设备属性历史数据
/*
    param name - 当前查询的属性名称
    param code - 当前查询的设备Code
    param time - 当前查询的结束日期
    param code1 - 当前查询的产品Code
    param time1 - 当前查询的起始日期
    param num - 当前查询的类型（1：属性，2：事件，3：服务）
    param pageNum - 当前查询页码
    param size - 每页显示的数据条数
*/
export function EquipPropertyHistory(name, code, time, code1, time1, num, pageNum, size) {
    return request({
        url: "/manager/device/historyDataQuery",
        method: 'post',
        data: {
            attributeName: name,
            deviceCode: code,
            endTime: time,
            productCode: code1,
            startTime: time1,
            type: num,
            page: pageNum,
            pageSize: size
        }
    })
}

// 批量导入设备中文件上传校验
/*
    param file - 当前上传的文件
*/
export function fileRules(data) {
    return request({
        url: "/manager/device/check",
        method: 'post',
        data: data
    })
}

// 批量导入设备
/*
    param code - 当前选择的产品Code
    param file - 当前上传的文件
*/
export function addEquipList(code, data) {
    return request({
        url: `/manager/device/import/${code}`,
        method: 'post',
        data: data
    })
}

// 获取设备详情页中的事件记录列表
/*
    param code - 设备code
    param time - 当前查询的结束时间
    param pageNum - 当前查询的页码
    param size - 当前每页查询的数据条数
    param code1 - 产品code
    param time1 - 当前查询的起始时间
    param num - 当前查询的类型（1：属性，2：事件，3：服务）
    param name - 当前查询的属性名称
*/
export function getEquipRecords(code, time, pageNum, size, code1, time1, num, name) {
    return request({
        url: "/manager/device/getEvents",
        method: 'post',
        data: {
            deviceCode: code,
            endTime: time,
            page: pageNum,
            pageSize: size,
            productCode: code1,
            startTime: time1,
            type: num,
            attributeName: name
        }
    })
}
